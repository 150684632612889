import React from 'react'
import Layout from "../components/layout"
import ContactForm from "../components/contact_form"


const ContactPage = () => {
    return (
    <Layout>
        <h1 className="mb-10 md:mb-16 text-center text-4xl font-semibold bg-gray-200">Contact Us</h1>
        <div className="mb-6 sm:mb-12">
        <ContactForm></ContactForm>
        </div>
    </Layout>
)}

export default ContactPage
